<template>
  <b-card>
    <template #header>
      <h6 class="mb-0">Delete channel</h6>
    </template>
    <b-card-text>
      <div class="content">
        <p>Completely remove channel...</p>
        <b-button variant="danger" v-b-modal.modal-confirm-delete>Delete</b-button>
        <b-modal id="modal-confirm-delete" title="Delete channel" @ok="confirmDelete()">
            <p class="my-4">Are you sure that you want to delete this channel?</p>
        </b-modal>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
    props: ['channel'],
    components: {

    },
    data(){
        return {

        };
    },
    methods:{
      confirmDelete(){
          this.$api.delete(`channel/${this.channel.id}`).then(() => {
            let channels = this.$store.getters['Callcenter/getChannels'].filter(o => o.id !== this.channel.id);

            this.$store.dispatch('Callcenter/addChannels', channels);
            //this.$router.push({name: "callcenter.channels"});
            //@TODO: remove the room from user
            document.location.href = this.$router.resolve({name: "callcenter.channels"}).href;
          });
      }
    },

    mounted(){
      this.$emit('set-channel',{activeSidebar: "delete"});
    }
};
</script>
